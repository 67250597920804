import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['controller', 'checkbox']
  
  initialize () {
    this.toggle = this.toggle.bind(this)
    this.refresh = this.refresh.bind(this)
  }
  
  connect () {
    if (!this.hasControllerTarget) return
  
    this.controllerTarget.addEventListener('change', this.toggle)
    this.checkboxTargets.forEach(checkbox => checkbox.addEventListener('change', this.refresh))
    this.refresh()
  }
  
  disconnect () {
    if (!this.hasCheckboxAllTarget) return
  
    this.controllerTarget.removeEventListener('change', this.toggle)
    this.checkboxTargets.forEach(checkbox => checkbox.removeEventListener('change', this.refresh))
  }
  
  toggle (e) {
    e.preventDefault()
  
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = this.controllerTarget.checked
      this.triggerInputEvent(checkbox)
    })
  }
  
  refresh () {
    const checkboxesCount = this.checkboxTargets.length
    const checkboxesCheckedCount = this.checked.length
    
    this.controllerTarget.indeterminate = (checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount)
    this.controllerTarget.checked = (checkboxesCheckedCount == checkboxesCount)
  }
  
  triggerInputEvent(checkbox) {
    const event = document.createEvent('HTMLEvents')
    event.initEvent('input', false, true)
    checkbox.dispatchEvent(event)
  }
  
  get checked () {
    return this.checkboxTargets.filter(checkbox => checkbox.checked)
  }
  
  get unchecked () {
    return this.checkboxTargets.filter(checkbox => !checkbox.checked)
  }
}